<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">招聘模块</a>
          <i>></i>
          <a href="javascript:;" @click="pageBack" class="cur-a"
            >人才库</a
          >
          <i>></i>
          <a href="javascript:;" class="cur-a">推荐设置</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <!-- 新增设置 - 弹框 -->
          <el-form
            :model="previewData"
            ref="ruleForm"
            :rules="rules"
            label-width="150px"
            ><el-form-item label="人才等级：" prop="talentLevel" class="f_1">
              <el-select
                clearable
                size="small"
                v-model="previewData.talentLevel"
                placeholder="请选择人才等级"
              >
                <el-option
                  v-for="item in talentLevelData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="推荐系数：" prop="recommendLevel" class="f_1">
              <el-select
                clearable
                size="small"
                v-model="previewData.recommendLevel"
                placeholder="请选择推荐系数"
              >
                <el-option
                  v-for="item in recommendLevelData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="擅长领域：" prop="skilledField" class="f_2">
              <el-input
                type="textarea"
                maxlength="200"
                show-word-limit
                v-model="previewData.skilledField"
              ></el-input>
            </el-form-item>
            <el-form-item label="兴趣爱好：" prop="hobbies" class="f_2">
              <el-input
                type="textarea"
                maxlength="200"
                show-word-limit
                v-model="previewData.hobbies"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                @click="addEditRecommendedSettingsOk('ruleForm')"
                class="bgc-bv"
                >确定</el-button
              >
            </el-form-item>
          </el-form>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="100px"
                :index="indexMethod"
              />
              <el-table-column
                label="操作人"
                align="left"
                prop="operator"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="操作时间"
                align="left"
                prop="createTime"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  {{ scope.row.createTime | momentDate }}
                </template>
              </el-table-column>
              <el-table-column
                label="操作内容"
                align="left"
                prop="remark"
                show-overflow-tooltip
                min-width="200"
              />
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "hr_recruitModule_personnelLibrary_recommendedSettings",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      // 人才等级 - 拉下数据
      talentLevelData: [
        {
          label: "一星",
          value: "1",
        },
        {
          label: "二星",
          value: "2",
        },
        {
          label: "三星",
          value: "3",
        },
        {
          label: "四星",
          value: "4",
        },
        {
          label: "五星",
          value: "5",
        },
      ],
      // 推荐系数 - 下拉数据
      recommendLevelData: [
        {
          label: "一星",
          value: "1",
        },
        {
          label: "二星",
          value: "2",
        },
        {
          label: "三星",
          value: "3",
        },
        {
          label: "四星",
          value: "4",
        },
        {
          label: "五星",
          value: "5",
        },
      ],
      // 新增设置 - 数据
      previewData: {
        talentId: "", // 人才id
        talentLevel: "", // 人才等级
        recommendLevel: "", // 推荐系数
        skilledField: "", // 擅长领域
        hobbies: "", // 兴趣爱好
      },
      // 新增设置 - 数据校验
      rules: {
        talentLevel: [
          {
            required: true,
            message: "请选择人才等级",
            trigger: "change",
          },
        ],
        recommendLevel: [
          {
            required: true,
            message: "请选择推荐系数",
            trigger: "change",
          },
        ]
      },
      retrievalData:{},
      currentPage:''
    };
  },
  computed: {},
  created() {
    this.previewData.talentId = this.$route.query.talentId;
    if(this.$route.query.retrievalData){
      this.retrievalData = this.$route.query.retrievalData
    }
    if(this.$route.query.currentPage){
      this.currentPage = this.$route.query.currentPage
    }
  },
  methods: {
    // 返回上一页
    pageBack(){
      this.$router.push({
        path: "/hr/recruitModule/personnelLibrary/personnelList",
        query: {
          retrievalData: this.retrievalData,
          currentPage: this.currentPage
        },
      });
    },
    // 获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        talentId: this.$route.query.talentId,
      };
      this.doFetch({
        url: "/hr/talent/info/settingLogPage",
        params,
        pageNum,
      });
    },
    // 获取列表数据 - 再次处理
    handleListDataBack(data) {
      this.tableData = data;
      this.$post("/hr/talent/info/getById", {
        talentId: this.$route.query.talentId,
      })
        .then((res) => {
          this.previewData.talentLevel = res.data.talentLevel
            ? String(res.data.talentLevel)
            : "";
          this.previewData.recommendLevel = res.data.recommendLevel
            ? String(res.data.recommendLevel)
            : "";
          this.previewData.skilledField = res.data.skilledField || "";
          this.previewData.hobbies = res.data.hobbies || "";
        })
        .catch(() => {
          return;
        });
    },
    // 新增设置 - 弹框
    addEditRecommendedSettings() {
      this.previewLoding = true;
    },
    // 新增设置 - 弹框 - 确定
    addEditRecommendedSettingsOk(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$post("/hr/talent/info/settingConfig", {
            ...this.previewData,
          })
            .then((res) => {
              this.$refs[formName].resetFields();
              this.getData();
              this.$message({
                message: res.message,
                type: "success",
              });
            })
            .catch(() => {
              return;
            });
        }
      });
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {},
};
</script>
<style lang="less" scoped>
.f_1 {
  width: 350px;
}
.f_2 {
  width: 500px;
}
</style>
